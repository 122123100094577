<template>
  <div>
    <h3>Stands Seleccionados</h3>
    <v-alert
      v-if="!selectedStands.length"
      outlined
      type="warning"
      class="mt-4 mb-4 ml-4 mr-4"
      border="left"
    >
      No has seleccionado ningún Stand aún
    </v-alert>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Espacio
          </th>
          <th class="text-left">
            Código
          </th>
          <th class="text-left">
            Categoría
          </th>
          <th class="text-left">
            Área
          </th>
          <th v-if="showValues" class="text-right">
            Total sin {{ taxNomenclature }}
          </th>
          <th v-if="showValues" class="text-right">
            Total con {{ taxNomenclature }}
          </th>
          <th v-if="!showValues"></th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="selectedStand in selectedStands"
          :key="selectedStand.id"
        >
          <td>{{ selectedStand.placeName }}</td>
          <td>{{ selectedStand.code.toUpperCase() }}</td>
          <td>{{ selectedStand.categoryName }}</td>
          <td>{{ selectedStand.area }}</td>
          <td v-if="showValues"  class="text-right">
            {{ copPaymentCurrency === 'COP' ? '$' : copPaymentCurrency === 'PEN' && peruFair ? 'S/' : '' }}
            {{ copPaymentCurrency === 'COP' ? selectedStand.copValue : copPaymentCurrency === 'PEN' && peruFair ? selectedStand.penValue : selectedStand.usdValue | currency}}
            {{ copPaymentCurrency === 'USD' ? 'USD' : '' }}
          </td>
          <td v-if="showValues"  class="text-right">
            {{ copPaymentCurrency === 'COP' ? '$' : copPaymentCurrency === 'PEN' && peruFair ? 'S/' : '' }}
            {{ copPaymentCurrency === 'COP' ? selectedStand.copValue + selectedStand.copTax : copPaymentCurrency === 'PEN' && peruFair ? selectedStand.penValue + selectedStand.penTax : selectedStand.usdValue + selectedStand.usdTax | currency }}
            {{ copPaymentCurrency === 'USD' ? 'USD' : '' }}
          </td>
          <td v-if="!showValues">
            <v-btn text @click="$emit('removeSelectedStand', selectedStand)">
              eliminar
            </v-btn>
          </td>
        </tr>
        <tr v-if="showValues && selectedStands.length">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td  class="text-right"><strong>Total: </strong>
            {{ copPaymentCurrency === 'COP' ? '$' : copPaymentCurrency === 'PEN' && peruFair ? 'S/' : '' }}
            {{ totalPrice | currency }}
            {{ copPaymentCurrency === 'USD' ? 'USD' : '' }}
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>

export default {
  computed: {
    totalPrice () {
      let totalPrice = 0
      this.selectedStands.forEach((stand) => {
        if (this.copPaymentCurrency === 'COP') {
          totalPrice += stand.copValue
          totalPrice += stand.copTax
        } else if (this.copPaymentCurrency === 'PEN' && this.peruFair) {
          totalPrice += stand.penValue
          totalPrice += stand.penTax
        } else {
          totalPrice += stand.usdValue
          totalPrice += stand.usdTax
        }
      })
      return totalPrice
    }
  },
  name: 'StandsTable',
  props: {
    copPaymentCurrency: {
      default: () => 'COP',
      type: String
    },
    selectedStands: {
      default: () => [],
      type: Array
    },
    showValues: {
      default: () => false,
      type: Boolean
    }
  },
  data () {
    return {
      peruFair: process.env.VUE_APP_PERU_FAIR === '1',
      taxNomenclature: process.env.VUE_APP_TAX_NOMENCLATURE
    }
  }
}
</script>
